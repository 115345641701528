
    
















// src/components/CsibonPresentation.js
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FaLeaf, FaMobileAlt, FaQrcode, FaRecycle, FaHeart, FaStore, FaEuroSign, FaMapMarkerAlt,FaCoffee,
    FaUtensils } from 'react-icons/fa';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 4rem;
`;



const Section = styled.section`
  margin-bottom: 4rem;
`;

const ImageSection = styled.section`
  margin: 4rem 0;
  display: flex;
  align-items: center;
  gap: 3rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  }
`;

const ImageContent = styled.div`
  flex: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const FeatureCard = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;

  .icon {
    font-size: 2.5rem;
    color: #3498db;
    margin-bottom: 1rem;
  }
`;

const StepCard = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);

  .number {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3498db;
  }
`;

const AppStoreButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
`;

const AppStoreButton = styled.a`
  display: inline-block;
  padding: 1rem 2rem;
  background: #000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ContactSection = styled.div`
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
`;








const HowItWorksSection = styled.section`
  margin: 6rem 0;
`;

const ExperienceIntro = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  padding: 0 1rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #2c3e50;
  }

  p {
    font-size: 1.2rem;
    color: #576574;
    line-height: 1.6;
  }
`;

const StepSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-bottom: 6rem;
  
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    
    &:nth-child(even) {
      flex-direction: column;
    }
  }
`;

const StepContent = styled.div`
  flex: 1;

  .step-number {
    font-size: 1.2rem;
    color: #3498db;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #2c3e50;
  }

  p {
    font-size: 1.1rem;
    color: #576574;
    line-height: 1.6;
  }
`;





const StepImage = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  
  img {
    width: 100%;
    max-width: 300px; // Réduit pour accommoder plusieurs images
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  }
`;


const HeroSection = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: #f8f9fa;
  min-height: 60vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
  }

  .hero-text {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem;
    background: #f8f9fa;

    @media (max-width: 1024px) {
      padding: 3rem;
      flex: 0 0 60%;
    }

    @media (max-width: 768px) {
      flex: none;
      width: 100%;
      padding: 2rem 1rem; // Réduit le padding horizontal
      text-align: center;
      order: 1;
      box-sizing: border-box;
    }

    h1 {
      font-size: clamp(2rem, 5vw, 3.5rem);
      font-weight: bold;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      
      @media (max-width: 768px) {
        padding: 0 10px;
      }

      span {
        color: #f1c40f;
      }
    }

    p {
      font-size: clamp(1rem, 2vw, 1.4rem);
      line-height: 1.6;
      margin-bottom: 2rem;
      color: #555;
      
      @media (max-width: 768px) {
        padding: 0 10px;
        margin-bottom: 1.5rem;
      }
    }

    a {
      display: inline-block;
      font-size: 1rem;
      padding: 0.8rem 1.5rem;
      background-color: #3498db;
      color: white;
      text-decoration: none;
      border-radius: 8px;
      font-weight: bold;
      transition: all 0.3s ease;
      white-space: nowrap; // Empêche le texte de se couper
      
      &:hover {
        background-color: #2980b9;
        transform: translateY(-2px);
      }

      @media (max-width: 768px) {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
        margin: 0 auto;
        width: auto; // Le bouton s'adapte au contenu
      }
    }
  }

  .hero-image {
    flex: 0 0 50%;
    position: relative;
    background: url('/screenshots/mockup.png') no-repeat center center;
    background-size: cover;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

    @media (max-width: 1024px) {
      flex: 0 0 40%;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    }

    @media (max-width: 768px) {
      order: 2;
      width: 100%;
      height: 300px; // Hauteur fixe sur mobile
      clip-path: none;
      background-size: contain; // Assure que l'image est visible entièrement
      background-position: center;
      margin-top: 1rem;
    }
  }
`;


const SuggestionSection = styled.div`
  background: linear-gradient(135deg, #3498db 0%, #2980b9 100%);
  padding: 4rem 2rem;
  border-radius: 15px;
  text-align: center;
  color: white;
  margin-bottom: 4rem;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: white;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
  }
`;

const SuggestionButtons = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const SuggestionButton = styled(motion.a)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  background-color: white;
  color: #3498db;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  min-width: 200px;

  @media (max-width: 768px) {
    width: 80%;
    justify-content: center;
  }

  .icon {
    font-size: 1.4rem;
  }

  &:hover {
    background-color: #f8f9fa;
    transform: translateY(-2px);
  }
`;



const breakpoints = {
  mobile: '576px',
  tablet: '768px',
  laptop: '1024px'
};

const Hero = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 6rem;
  background-color: #f8f9fa;
  min-height: 80vh;
  margin-top: 70px;

  h1 {
    font-size: clamp(2rem, 5vw, 3.5rem);
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    
    @media (max-width: 768px) {
      padding: 0 10px;
    }

    span {
      color: #f1c40f;
    }
  }

  p {
    font-size: clamp(1rem, 2vw, 1.4rem);
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #555;
    
    @media (max-width: 768px) {
      padding: 0 10px;
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    padding: 3rem 4rem;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    padding: 2rem;
    text-align: center;
  }
`;

const HeroContent = styled.div`
  flex: 1;
  text-align: left;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  color: #4CAF50;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.laptop}) {
    font-size: 3rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.6;

  @media (max-width: ${breakpoints.laptop}) {
    font-size: 1.3rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.1rem;
  }
`;

const AppShowcase = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    height: 800px;
    transform: perspective(1000px) rotateY(-15deg);
    
    @media (max-width: ${breakpoints.laptop}) {
      height: 600px;
    }

    @media (max-width: ${breakpoints.tablet}) {
      height: 500px;
      transform: none;
    }

    @media (max-width: ${breakpoints.mobile}) {
      height: 400px;
      width: 100%;
      object-fit: contain;
    }
  }
`;



const DownloadButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: ${breakpoints.tablet}) {
    justify-content: center;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StoreButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2rem;
  background: ${props => props.variant === 'apple' ? '#000000' : '#4CAF50'};
  border-radius: 8px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: opacity 0.3s ease;
  max-width: 200px;

  &:hover {
    opacity: 0.9;
  }

  img {
    height: 24px;
    margin-right: 8px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const StoreButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span:first-child {
    font-size: 0.8rem;
  }

  span:last-child {
    font-size: 1.1rem;
  }
`;

function CsibonPresentation() {
  return (
    


<>
<Hero>
        <HeroContent>

      
          <motion.div
    className="hero-text"
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.8 }}
  >
    <h1>
      Bienvenue sur <span>CSIBON</span>
    </h1>
    <p>
      Transformez vos habitudes de consommation tout en soutenant des causes sociales et écologiques.
    </p>
    
  </motion.div>
          <DownloadButtons>
            <StoreButton 
              href="https://apps.apple.com/fr/app/csibon/id6477392997" 
              target="_blank"
              rel="noopener noreferrer"
              variant="apple"
            >
              <StoreButtonContent>
                <span>Télécharger sur</span>
                <span>App Store</span>
              </StoreButtonContent>
            </StoreButton>
            <StoreButton 
              href="https://play.google.com/store/search?q=csibon&c=apps&hl=fr" 
              target="_blank"
              rel="noopener noreferrer"
              variant="google"
            >
              <StoreButtonContent>
                <span>Disponible sur</span>
                <span>Google Play</span>
              </StoreButtonContent>
            </StoreButton>
          </DownloadButtons>
        </HeroContent>
       
        <AppShowcase>
          <img 
            src="/mockup2.png" 
            alt="Application Hoali montrant le calendrier et la carte" 
          />
        </AppShowcase>
      </Hero>

      
<PageContainer>
<Section>
  <h2>Notre Mission</h2>
  <Grid>

  <FeatureCard whileHover={{ y: -5 }}>
      <FaMobileAlt className="icon" />
      <h3>Pratique</h3>
      <p>Commandez et payez rapidement depuis votre smartphone</p>
    </FeatureCard>

    <FeatureCard whileHover={{ y: -5 }}>
      <FaEuroSign className="icon" />
      <h3>Économique</h3>
      <p>Bénéficiez de réductions sur vos commandes grâce à vos actes écologiques</p>
    </FeatureCard>

    <FeatureCard whileHover={{ y: -5 }}>
      <FaMapMarkerAlt className="icon" />
      <h3>Local</h3>
      <p>Soutenez l'économie locale en collaborant avec des établissements et producteurs de votre région</p>
    </FeatureCard>

    <FeatureCard whileHover={{ y: -5 }}>
      <FaHeart className="icon" />
      <h3>Engagé</h3>
      <p>Soutenez des causes sociales via notre partenariat avec La Cloche</p>
    </FeatureCard>


    <FeatureCard whileHover={{ y: -5 }}>
      <FaLeaf className="icon" />
      <h3>Écologique</h3>
      <p>Réduisez votre impact environnemental en utilisant des contenants réutilisables</p>
    </FeatureCard>

   


   

  
  </Grid>
</Section>

     

<HowItWorksSection>
  <ExperienceIntro>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <h2>Une expérience simple et rapide</h2>
      <p>
      CSIBON révolutionne votre expérience de consommation en quelques étapes simples. Découvrez comment utiliser notre application pour profiter de vos plats et boissons préférés tout en respectant l'environnement
      </p>
    </motion.div>
  </ExperienceIntro>


   <ImageSection>
        <ImageContainer>
          <motion.img 
            src="/csibon-usage.png" 
            alt="Utilisation de CSIBON dans un café"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          />
        </ImageContainer>
        <ImageContent>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h2>Une expérience simple et rapide</h2>
            <p>
            Avec CSIBON, plus besoin de faire la queue ! Commandez votre plat ou boisson, présentez votre QR code et récupérez votre commande dans votre contenant réutilisable ou dans un contenant mis à disposition. C'est aussi simple que ça !            </p>
          </motion.div>
        </ImageContent>
      </ImageSection>


      {[
    {
      number: `ÉTAPE 1`,
      title: `Inscrivez-vous en quelques clics`,
      content: `Créez votre compte rapidement et ajoutez votre moyen de paiement préféré de manière sécurisée. Personnalisez votre profil pour une expérience sur mesure.`,
      images: ["/screenshots/inscription.jpg"]
    },
    {
      number: `ÉTAPE 2`,
      title: `Choisissez votre plat ou boisson`,
      content: `Parcourez notre sélection de plats et boissons et découvrez les établissements partenaires près de chez vous.`,
      images: ["/screenshots/selection-1.jpg"]
    },
    {
      number: `ÉTAPE 3`,
      title: `Sélectionnez votre contenant`,
      content: `Optez pour l'utilisation de votre propre contenant ou utilisez un contenant réutilisable mis à disposition.`,
      images: ["/screenshots/selection-2.jpg","/screenshots/container-1.jpg"]
    },
    {
      number: `ÉTAPE 4`,
      title: `Validez votre commande`,
      content: `Présentez le QR code généré au comptoir. Le professionnel valide votre commande et prépare votre boisson dans le contenant choisi.`,
      images: ["/screenshots/qrcode-1.jpg", "/screenshots/qrcode-2.jpg"]
    },
    {
      number: `ÉTAPE 5`,
      title: `Profitez et faites réutiliser le contenant mis à disposition`,
      content: `Savourez votre boisson et rapportez le contenant mis à disposition dans n'importe quel établissement partenaire pour qu'il puisse être réutilisé.`,
      images: ["/screenshots/return.jpg"]
    }
  ].map((step, index) => (
    <StepSection key={index}>
      <motion.div
        initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <StepContent>
          <div className="step-number">{step.number}</div>
          <h3>{step.title}</h3>
          <p>{step.content}</p>
        </StepContent>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <StepImage>
          {step.images.map((image, imageIndex) => (
            <motion.img 
              key={imageIndex}
              src={image} 
              alt={`${step.title} - Image ${imageIndex + 1}`}
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: imageIndex * 0.2 }}
            />
          ))}
        </StepImage>
      </motion.div>
    </StepSection>
  ))}


</HowItWorksSection>


      <Section>
        <h2>Gestion des Contenants</h2>
        <Grid>
          <FeatureCard whileHover={{ y: -5 }}>
            <FaRecycle className="icon" />
            <h3>Flexibilité Totale</h3>
            <p>Rendez votre contenant dans n'importe quel établissement du réseau</p>
          </FeatureCard>

       

          <FeatureCard whileHover={{ y: -5 }}>
  <FaStore className="icon" />
  <h3>Un Réseau en Expansion</h3>
  <p>Déjà 3 établissements partenaires, et ce n'est que le début ! </p>
</FeatureCard>

        </Grid>
      </Section>

      {/* <Section id="download">
  <h2>Téléchargez CSIBON</h2>
  <AppStoreButtons>
    <AppStoreButton href="https://apps.apple.com/fr/app/csibon/id6477392997" target="_blank">
      <FaMobileAlt /> App Store
    </AppStoreButton>
    <AppStoreButton href="https://play.google.com/store/search?q=csibon&c=apps&hl=fr" target="_blank">
      <FaMobileAlt /> Google Play
    </AppStoreButton>
  </AppStoreButtons>
</Section> */}

<SuggestionSection>
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.6 }}
  >
    <h2>Participez à l'aventure CSIBON</h2>
    <p>
      Vous connaissez un établissement qui serait parfait pour CSIBON ? 
      Envie de voir vos boissons et plats préférés dans l'application ? 
      Partagez vos suggestions avec nous !
    </p>
    <SuggestionButtons>
      <SuggestionButton
        href="https://forms.gle/VotreFormulairePourEtablissements" 
        target="_blank"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <FaStore className="icon" />
        Suggérer un établissement
      </SuggestionButton>
      <SuggestionButton
        href="https://forms.gle/VotreFormulairePourBoissons" 
        target="_blank"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <FaCoffee className="icon" />
        Proposer une boisson
      </SuggestionButton>
      <SuggestionButton
        href="https://forms.gle/VotreFormulairePourFood" 
        target="_blank"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <FaUtensils className="icon" />
        Proposer un plat
      </SuggestionButton>
    </SuggestionButtons>
  </motion.div>
</SuggestionSection>

      <ContactSection>
        <h2>Contactez-nous</h2>
        <p>Vous avez des questions ? Notre équipe est là pour vous aider !</p>
        <p>Email : contact@csibon.green</p>
        {/* <p>Téléphone : XX XX XX XX XX</p> */}
        <p>Suivez-nous sur les réseaux sociaux pour ne rien manquer !</p>
      </ContactSection>
    </PageContainer>
    </>
  );
}

export default CsibonPresentation;

