// src/components/UserTypeNav.js
import styled from 'styled-components';

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;


`;

const NavButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: 2px solid #3498db;
  background-color: ${props => props.active ? '#3498db' : 'transparent'};
  color: ${props => props.active ? 'white' : '#3498db'};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  

  &:hover {
    background-color: ${props => props.active ? '#2980b9' : '#e8f4fc'};
  }
`;

function UserTypeNav({ activeType, onTypeChange }) {
  return (
    <NavContainer>
      <NavButton 
        active={activeType === 'particulier'} 
        onClick={() => onTypeChange('particulier')}
      >
        Je suis un particulier
      </NavButton>
      <NavButton 
        active={activeType === 'professionnel'} 
        onClick={() => onTypeChange('professionnel')}
      >
        Je suis un professionnel
      </NavButton>
    </NavContainer>
  );
}

export default UserTypeNav;