// src/App.js
import React, { useState } from 'react';
import CsibonPresentation from './components/CsibonPresentation';
import ProfessionnelPresentation from './components/ProfessionnelPresentation';
import UserTypeNav from './components/UserTypeNav';

function App() {
  const [userType, setUserType] = useState('particulier');

  return (
    <div className="App">
      <UserTypeNav activeType={userType} onTypeChange={setUserType} />
      {userType === 'particulier' ? (
        <CsibonPresentation />
      ) : (
        <ProfessionnelPresentation />
      )}
    </div>
  );
}

export default App;