// src/components/ProfessionnelPresentation.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { 
  FaStore, 
  FaChartLine, 
  FaUsers, 
  FaRecycle, 
  FaMobileAlt, 
  FaLeaf, 
  FaBuilding, 
  FaChartBar, 
  FaHandshake,
  FaCoffee,
  FaQrcode, 
  FaHistory, 
  FaCog, 
  FaListAlt, 
  FaBoxes, 
  FaChartPie 
} from 'react-icons/fa';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeroSection = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: #f8f9fa;
  min-height: 60vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
  }

  .hero-text {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem;
    background: #f8f9fa;

    @media (max-width: 1024px) {
      padding: 3rem;
      flex: 0 0 60%;
    }

    @media (max-width: 768px) {
      flex: none;
      width: 100%;
      padding: 2rem 1rem;
      text-align: center;
      order: 1;
      box-sizing: border-box;
    }

    h1 {
      font-size: clamp(2rem, 5vw, 3.5rem);
      font-weight: bold;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      
      @media (max-width: 768px) {
        padding: 0 10px;
      }
    }

    p {
      font-size: clamp(1rem, 2vw, 1.4rem);
      line-height: 1.6;
      margin-bottom: 2rem;
      color: #555;
      
      @media (max-width: 768px) {
        padding: 0 10px;
        margin-bottom: 1.5rem;
      }
    }
  }

  .hero-image {
    flex: 0 0 50%;
    position: relative;
    background: url('/screenshots/pro-hero.png') no-repeat center center;
    background-size: cover;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);

    @media (max-width: 1024px) {
      flex: 0 0 40%;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    }

    @media (max-width: 768px) {
      order: 2;
      width: 100%;
      height: 300px;
      clip-path: none;
      background-size: contain;
      background-position: center;
      margin-top: 1rem;
    }
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 4rem 0;
  padding: 0 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Tab = styled.button`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: 2px solid #3498db;
  background-color: ${props => props.active ? '#3498db' : 'transparent'};
  color: ${props => props.active ? 'white' : '#3498db'};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 200px;

  &:hover {
    background-color: ${props => props.active ? '#2980b9' : '#e8f4fc'};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Section = styled.section`
  margin: 4rem 0;
  padding: 0 1rem;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #2c3e50;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const FeatureCard = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;

  .icon {
    font-size: 2.5rem;
    color: #3498db;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #2c3e50;
  }

  p {
    color: #666;
    line-height: 1.6;
  }
`;

const ContactSection = styled.div`
  background: #f8f9fa;
  padding: 3rem 2rem;
  border-radius: 10px;
  text-align: center;
  margin-top: 4rem;

  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #2c3e50;
  }

  p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 1rem;
  }
`;

const CTAButton = styled(motion.a)`
  display: inline-block;
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;



const DashboardPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-top: 3rem;
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 15px;

  img {
    flex: 1;
    max-width: 50%;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;

    img {
      max-width: 100%;
    }
  }
`;

const DashboardFeatures = styled.div`
  flex: 1;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  .icon {
    font-size: 1.5rem;
    color: #3498db;
    margin-top: 0.3rem;
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
  }

  p {
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

function ProfessionnelPresentation() {
  const [activeTab, setActiveTab] = useState('chr');

  return (
    <PageContainer>
      <HeroSection>
        <motion.div
          className="hero-text"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1>CSIBON pour les Professionnels</h1>
          <p>
            Rejoignez notre réseau et contribuez à la transition écologique, 
            que vous soyez un établissement CHR ou une entreprise engagée.
          </p>
        </motion.div>
        <div className="hero-image" />
      </HeroSection>

      <TabContainer>
        <Tab 
          active={activeTab === 'chr'} 
          onClick={() => setActiveTab('chr')}
        >
          Établissements CHR
        </Tab>
        <Tab 
          active={activeTab === 'entreprise'} 
          onClick={() => setActiveTab('entreprise')}
        >
          Entreprises
        </Tab>
      </TabContainer>

      {activeTab === 'chr' ? (
        <>
         <Section>
      <h2>Solutions pour les établissements CHR</h2>
      <Grid>
        <FeatureCard whileHover={{ y: -5 }}>
          <FaStore className="icon" />
          <h3>Gestion de votre établissement</h3>
          <p>Personnalisez votre profil, horaires, et gérez votre visibilité sur l'application</p>
        </FeatureCard>

        <FeatureCard whileHover={{ y: -5 }}>
          <FaChartLine className="icon" />
          <h3>Gestion des produits</h3>
          <p>Créez et modifiez vos produits, gérez vos prix et votre stock en temps réel</p>
        </FeatureCard>

        <FeatureCard whileHover={{ y: -5 }}>
          <FaRecycle className="icon" />
          <h3>Gestion des contenants</h3>
          <p>Suivez et gérez votre stock de contenants réutilisables mis à disposition</p>
        </FeatureCard>

        <FeatureCard whileHover={{ y: -5 }}>
          <FaUsers className="icon" />
          <h3>Fidélisation clients</h3>
          <p>Programme de récompenses écologiques pour fidéliser votre clientèle</p>
        </FeatureCard>
      </Grid>
    </Section>

    <Section>
      <h2>Application tablette dédiée</h2>
      <Grid>
        <FeatureCard whileHover={{ y: -5 }}>
          <FaMobileAlt className="icon" />
          <h3>Commandes en temps réel</h3>
          <p>Recevez et gérez vos commandes instantanément sur votre tablette</p>
        </FeatureCard>

        <FeatureCard whileHover={{ y: -5 }}>
          <FaQrcode className="icon" />
          <h3>Validation des commandes</h3>
          <p>Scannez et validez rapidement les commandes clients via QR code</p>
        </FeatureCard>

        <FeatureCard whileHover={{ y: -5 }}>
          <FaHistory className="icon" />
          <h3>Historique détaillé</h3>
          <p>Accédez à l'historique complet de vos commandes et transactions</p>
        </FeatureCard>

        <FeatureCard whileHover={{ y: -5 }}>
          <FaChartBar className="icon" />
          <h3>Statistiques et rapports</h3>
          <p>Suivez vos performances et l'impact environnemental de votre établissement</p>
        </FeatureCard>
      </Grid>
    </Section>

    <Section>
      <h2>Backoffice complet</h2>
      <DashboardPreview>
        {/* <motion.img 
          src="/screenshots/dashboard-preview.png" 
          alt="Aperçu du backoffice"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        /> */}
        <DashboardFeatures>
          <FeatureList>
            <FeatureItem>
              <FaCog className="icon" />
              <div>
                <h3>Paramètres de l'établissement</h3>
                <p>Gérez vos informations, horaires, et photos</p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <FaListAlt className="icon" />
              <div>
                <h3>Gestion des produits</h3>
                <p>Créez et modifiez votre catalogue de produits</p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <FaBoxes className="icon" />
              <div>
                <h3>Gestion des stocks</h3>
                <p>Suivez vos contenants disponibles en temps réel</p>
              </div>
            </FeatureItem>
            <FeatureItem>
              <FaChartPie className="icon" />
              <div>
                <h3>Analyses et statistiques</h3>
                <p>Consultez vos rapports de performance</p>
              </div>
            </FeatureItem>
          </FeatureList>
        </DashboardFeatures>
      </DashboardPreview>
    </Section>
        </>
      ) : (
        <>
          <Section>
            <h2>Solutions RSE pour les entreprises</h2>
            <Grid>
              <FeatureCard whileHover={{ y: -5 }}>
                <FaBuilding className="icon" />
                <h3>Engagement RSE</h3>
                <p>Renforcez votre politique RSE avec des actions concrètes et mesurables</p>
              </FeatureCard>

              <FeatureCard whileHover={{ y: -5 }}>
                <FaChartBar className="icon" />
                <h3>Métriques détaillées</h3>
                <p>Suivez l'impact environnemental et les économies de CO2 de vos collaborateurs</p>
              </FeatureCard>

              <FeatureCard whileHover={{ y: -5 }}>
                <FaHandshake className="icon" />
                <h3>Engagement collaborateurs</h3>
                <p>Motivez vos équipes autour d'objectifs écologiques communs</p>
              </FeatureCard>

              <FeatureCard whileHover={{ y: -5 }}>
                <FaLeaf className="icon" />
                <h3>Impact positif</h3>
                <p>Rapports d'impact environnemental pour vos bilans RSE</p>
              </FeatureCard>
            </Grid>
          </Section>

          <Section>
            <h2>Dashboard RSE</h2>
            <Grid>
              <FeatureCard whileHover={{ y: -5 }}>
                <FaChartLine className="icon" />
                <h3>Statistiques détaillées</h3>
                <p>Suivi en temps réel des économies de CO2 réalisées par vos collaborateurs</p>
              </FeatureCard>

              <FeatureCard whileHover={{ y: -5 }}>
                <FaMobileAlt className="icon" />
                <h3>Reporting automatisé</h3>
                <p>Génération automatique de rapports RSE pour vos communications internes et externes</p>
              </FeatureCard>
            </Grid>
          </Section>
        </>
      )}

      <ContactSection>
        <h2>Devenez partenaire</h2>
        <p>Vous souhaitez intégrer CSIBON dans votre stratégie RSE ou votre établissement ?</p>
        <p>Email : partenaires@csibon.green</p>
        {/* <p>Téléphone : XX XX XX XX XX</p> */}
        <CTAButton 
          href="#contact"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Contactez-nous
        </CTAButton>
      </ContactSection>
    </PageContainer>
  );
}

export default ProfessionnelPresentation;